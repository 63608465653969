import { Box, Heading, Text, SimpleGrid } from "@chakra-ui/react";
import { MotionElement } from "../comon/MotionElement";

export const RecaptchaHero = () => {
  
  return (
   <Box>
     <MotionElement type="fadeInY">
          <Heading as="h2" color={"gray.600"}>
            <Text as={"span"} color={"#319795"}>
            It's Not Just 
            </Text>{" "}
           
            <Text as={"span"} className="primary-color">
              {" "}
              a Game
            </Text>
          </Heading>
    </MotionElement>
     <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} p={0} mt={9}>
      <Box p={6} bg="white" boxShadow="md" borderRadius="md">
        <MotionElement type="fadeInY">
          <Heading as="h4" fontSize={27} mb={8} color={"gray.600"}>
            <Text as={"span"} color={"#319795"}>
              Your success
            </Text>{" "}
            is our
            <Text as={"span"} className="primary-color">
              {" "}
              highest priority
            </Text>
          </Heading>
        </MotionElement>
        <MotionElement type="fadeInX">
          <Text as="p" fontSize="lg">
            <Text as={"span"} color={"#319795"} fontWeight={600}>
              ThelastSpellingBee App
            </Text>{" "}
            is designed to enhance your ability to remember and retain
            information. Whether you're preparing for a spelling bee or simply
            looking to improve your language skills, our app provides a
            user-friendly experience. With interactive features and personalized
            learning, success is just a step away.
          </Text>
        </MotionElement>
      </Box>
      <Box p={6} bg="white" boxShadow="md" borderRadius="md">
        <MotionElement type="fadeInY">
          <Heading as="h4" fontSize={27} mb={8} color={"gray.600"}>
            <Text as={"span"} color={"#319795"}>
              Preventing
            </Text>{" "}
            spam and abuse with
            <Text as={"span"} className="primary-color">
              {" "}
              TheLastSpellingBee API
            </Text>
          </Heading>
        </MotionElement>
        <MotionElement type="fadeInX">
          <Text as="p" fontSize="lg">
            TheLastSpellingBee's API is a powerful tool to distinguish between
            humans and bots on websites. By integrating{" "}
            <Text as={"span"} color={"#319795"} fontWeight={600} ml={1}>
              ThelastSpellingBee's Re-CAPTCHA
            </Text>{" "}
            into your website, you can prevent automated programs from accessing
            the site, ensuring a secure and spam-free environment for legitimate
            users. The API is designed to be simple for humans and challenging
            for bots, making it an effective solution against malicious
            activities.
          </Text>
        </MotionElement>
      </Box>
     </SimpleGrid>
   </Box>
  );
};
