import { Alert, AlertIcon } from "@chakra-ui/react";
import { useState } from "react";

export const useAnswerFeedback = () => {
  const [no1] = useState(new Audio("/sounds/no/1.wav"));
  const [no2] = useState(new Audio("/sounds/no/2.wav"));
  const [no3] = useState(new Audio("/sounds/no/3.wav"));
  const [no4] = useState(new Audio("/sounds/no/3.wav"));
  const [yes1] = useState(new Audio("/sounds/yes/1.wav"));
  const [yes2] = useState(new Audio("/sounds/yes/2.wav"));
  const [yes3] = useState(new Audio("/sounds/yes/3.wav"));


  const soundEffect = (status: boolean) => {
    const index = Math.floor(Math.random() * 4);
    const yesSounds = [yes1, yes2, yes3, yes2];
    const noSounds = [no1, no2, no3, no4];

    let currentSound = status ? yesSounds[index] : noSounds[index];

    currentSound.play();

    // Pause the sound after 1 second
    // setTimeout(() => {
    //   // currentSound.pause();
    //   // currentSound.currentTime = 0; // Reset the currentTime for potential future plays
    //   // no1.load(); // Reload the audio file to clear the buffer
    // }, 2000);
  };

  const renderAnswer = () => {
    const decodedString = atob(localStorage.getItem("backfeedeng") ?? "{}");
    const feedback = JSON.parse(decodedString);

    const currentScore = parseInt(
      atob(localStorage.getItem("iendmygame") ?? "0"),
    );
    let newScore = currentScore;
    const getStatusAlert = (status: boolean) => {
      switch (status) {
        case true:
          soundEffect(true);
          newScore += 5;
          return "success";
        case false:
          soundEffect(false);
          return "error";
        default:
          return "error";
      }
    };
    const alertStatus = getStatusAlert(feedback.status);

    const base64EncodedScore = btoa(newScore.toString() ?? "0");

    localStorage.setItem("iendmygame", base64EncodedScore);
    return (
      <Alert status={alertStatus} borderRadius={4} mt={20}>
        <AlertIcon />
        {feedback.message ?? ""}
      </Alert>
    );
  };

  return { renderAnswer };
};
