import { ColorModeScript } from "@chakra-ui/react"
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import  App  from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import './custom-style.css';
import { Provider } from "react-redux"
import store, { persistor } from "./redux/store"
import { PersistGate } from "redux-persist/integration/react"
import { GoogleOAuthProvider } from '@react-oauth/google';


const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)

root.render(
  <GoogleOAuthProvider clientId="291778238094-oo02thbju9iq0vk44mbm8a6ggnvf4n8m.apps.googleusercontent.com">
  <Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
    <ColorModeScript />
    <App />
    </PersistGate>
</Provider>
</GoogleOAuthProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

