import { FC } from "react";
import { AspectRatio, Box, Heading, Text, Button } from "@chakra-ui/react";

interface VideoProps {
  videoUrl: string;
}
export const VideoAboutTheLastSpellingBee: FC<VideoProps> = ({ videoUrl }) => {
  const videoId = videoUrl.split("v=")[1].split("&")[0];

  return (
    <Box width={"100%"}>
      <Heading as="h2" color={"gray.600"}>
        <Text as={"span"} color={"#319795"}>
          About
        </Text>{" "}
        <Text as={"span"} className="primary-color">
          {" "}
          TheLastSpellingBee
        </Text>
      </Heading>
      <AspectRatio maxW="100%" ratio={16 / 9} mt={9}>
        <iframe
          title="About TheLastSpelling Bee Video"
          src={`https://www.youtube.com/embed/${videoId}`}
          allowFullScreen
        />
      </AspectRatio>
      <Button
        as={"a"}
        href="https://thelastspellingbee.com/spell/128-2eb3f394-56be-4622-92a8-46febf1e6c57"
        marginTop={30}
        ml={{ base: 0, md: 9 }}
        color="#F26B3A"
        variant="outline"
      >
        Try demo game
      </Button>
    </Box>
  );
};
