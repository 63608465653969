import { useState } from "react";
import {
  Box,
  Heading,
  Text,
  useColorModeValue,
  Container,
  VStack,
  Button,
  Slide,
} from "@chakra-ui/react";
import { CreateGame } from "./CreateGame";
import { MotionElement } from "../comon/MotionElement";
import { AnimatedTypingText } from "../comon/AnimatedTypingText";
import { RecaptchaHero } from "../recaptcha/RecaptchaHero";
import { VideoAboutTheLastSpellingBee } from "../recaptcha/VideoAboutTheLastSpellingBee";

export const Home = () => {
  const [isCreateGameVisible, setCreateGameVisible] = useState(false);

  const handleStartNewGame = () => {
    setCreateGameVisible(true);
  };
  const handleCloseCreateGame = () => {
    setCreateGameVisible(false);
  };

  return (
    <Container maxW={"7xl"} p="12">
      <Box
        marginTop={{ base: "50", sm: "129" }}
        display="flex"
        flexDirection={{ base: "column", sm: "row" }}
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center"
        >
          <Box
            width={{ base: "100%", sm: "85%" }}
            zIndex="2"
            marginLeft={{ base: "0", sm: "5%" }}
            marginTop="5%"
            textAlign={"center"}
          >
            <MotionElement type="fadeInX">
              <Heading
                as="h4"
                textAlign={"left"}
                color={"gray.600"}
                data-testid="header-title"
              >
                Ready to lay your way to a better memory?
                <span className="primary-color"> Start Today!</span>
              </Heading>
            </MotionElement>
            <Box color={"gray.600"} textAlign={"left"}>
              <MotionElement type="fadeInY">
                <Text color={"gray.600"} mt={9}>
                  Your Journey to Unleash Your Inner Memory Maestro Begins with TheLastSpellingBe
                </Text>
              </MotionElement>
            </Box>
           <Box textAlign={"left"}>
            <MotionElement type="fadeInX">
              {!isCreateGameVisible && (
                <><Button
                    data-testid="start-new-game"
                    marginTop={30}
                    colorScheme="teal"
                    variant="outline"
                    onClick={handleStartNewGame}
                  >
                    Start new game
                  </Button>
                  <Button
                    as={"a"}
                    href="https://thelastspellingbee.com/spell/128-2eb3f394-56be-4622-92a8-46febf1e6c57"
                    marginTop={30}
                    ml={{base:0, md:9}}
                    color="#F26B3A"
                    variant='outline'
                  >
                      Try demo game
                  </Button></>
              )}
            </MotionElement>
           </Box>
            {isCreateGameVisible && (
              <Slide
                direction="right"
                in={isCreateGameVisible}
                style={{ 
                  zIndex: 10, 
                  marginTop:-190, 
                  position:"absolute", 
                  width: 850 }}
              >
                <Box mt={200} ml={{ base: 550, md: 300 }} width={{base: 350, md: '100%'}}>
                  <CreateGame
                    isOpen={isCreateGameVisible}
                    onClose={handleCloseCreateGame}
                  />
                </Box>
              </Slide>
            )}
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box backgroundSize="20px 20px" opacity="0.4" height="100%" />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: "3", sm: "0" }}
        >
          <Heading marginTop="1"></Heading>
          <Box
            marginTop="2"
            color={useColorModeValue("gray.700", "gray.200")}
            fontSize="lg"
          >
            <Text
              as={"span"}
              align={"left"}
              className="textTheLastSpellingBee3D"
            >
              <AnimatedTypingText text="A1-B2-C3-D4" speed={0.3}></AnimatedTypingText>
            </Text>

            <Box>
              <Text
                as={"span"}
                w={"full"}
                marginTop={3}
                color={"gray.600"}
                align={"left"}
              >
                <AnimatedTypingText text="Join the Memory Revolution, Where Fun Meets Cognitive Growth with"></AnimatedTypingText>

                <Text
                  as={"span"}
                  color={"#319795"}
                  fontWeight={600}
                  align={"left"}
                >
                  {" "}
                  <AnimatedTypingText text="ThelastSpellingBee"></AnimatedTypingText>
                </Text>
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <VStack
        paddingTop="40px"
        spacing="2"
        alignItems="flex-start"
        marginTop={9}
      >
      <VideoAboutTheLastSpellingBee videoUrl={"https://www.youtube.com/watch?v=FFc2Pu7EcOg&t=23s"} />
      </VStack>
      <VStack
        paddingTop="40px"
        spacing="2"
        alignItems="flex-start"
        marginTop={9}
      >
       <RecaptchaHero />
      </VStack>
      
    </Container>
  );
};
