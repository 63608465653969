import { Box, IconButton, useBreakpointValue } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'

interface Props {
  onShowSidebar: React.MouseEventHandler<HTMLButtonElement>;
  showSidebarButton?: boolean;
}

export const ApiReferenceHeader = ({ showSidebarButton = true, onShowSidebar }: Props) => {
  const isMobile = useBreakpointValue({ base: false, md: true }); 
  return (
    <Box  width={'100%'}>
      <Box flex="1">
        
        {showSidebarButton && (
          isMobile ? 
          <IconButton
            icon={<ChevronRightIcon w={8} h={8} />}
            colorScheme="blackAlpha"
            variant="outline"
            onClick={onShowSidebar} aria-label={''}  />
            : ""
        )}
      </Box>
     
    </Box>
  )
}
