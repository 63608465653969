import {
  Box,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import { SidebarApiReference } from "./SidebarApiReference";
import { useState } from "react";
import { ApiReferenceHeader } from "./ApiReferenceHeader";
const smVariant = { navigation: "drawer", navigationButton: true };
const mdVariant = { navigation: "sidebar", navigationButton: false };

export const ApiReferenceMenu = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const variants = useBreakpointValue({ base: smVariant, md: mdVariant });

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  return (
    <Flex minH={"100vh"} direction={{ base: "column", md: "row" }} pt={0}>
    <Box ml={(!variants?.navigationButton as any) && 200} width={'100%'}>
      <SidebarApiReference
        variant={variants?.navigation}
        isOpen={isSidebarOpen}
        onClose={toggleSidebar}
      />
      <ApiReferenceHeader
        showSidebarButton={variants?.navigationButton}
        onShowSidebar={toggleSidebar}
      />
    </Box>
    </Flex>
  );
};
