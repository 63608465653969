import React, { FC } from "react";
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Text,
  Avatar,
  useToast,
} from "@chakra-ui/react";
import { getInviteLink } from "./game-play/helper";

interface InvitePlayersProps {
  inviteId: string;
}
export const InvitePlayers: FC<InvitePlayersProps> = ({
  inviteId
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const toast = useToast();

  const inviteLink = getInviteLink(inviteId);

  return (
    <>
      <Button
        onClick={onOpen}
        variant="outline"
        border={"2px #F26B3A dotted"}
        padding={6}
      >
        <Avatar size="sm" bg="#F26B3A" />
        <Text ml={9}>Invite players ✌️</Text>
      </Button>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent p={3}>
          <ModalHeader>Invite players</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Game's url</FormLabel>
              <Input
                ref={initialRef}
                placeholder="Game's url"
                value={inviteLink}
                readOnly
              />
            </FormControl>
          </ModalBody>

          <Button
            bg={"#319795"}
            color={"white"}
            _hover={{
              bg: "#319795",
            }}
            onMouseDown={() =>
              toast({
                title: "Link copied..",
                description: "We've copied the game url",
                status: "success",
                position: "top-right",
                duration: 9000,
                isClosable: true,
              })
            }
            onClick={() => navigator.clipboard.writeText(inviteLink)}
          >
            Copy Invitation Link
          </Button>
        </ModalContent>
      </Modal>
    </>
  );
};
