import { Box } from "@chakra-ui/react";
import "./AnimatedBackground.css";
import { useEffect, useState } from "react";

const charNum = (index: number) => {
  let content: any = "";
  switch (index) {
    case 1:
      content = (
        <>
          <li>
            <span>1</span>
          </li>
          <li>
            <span>A</span>
          </li>
          <li>
            <span>2</span>
          </li>
          <li>
            <span>B</span>
          </li>
          <li>
            <span>3</span>
          </li>
          <li>
            <span>C</span>
          </li>
          <li>
            <span>D</span>
          </li>
          <li>
            <span>4</span>
          </li>
          <li>
            <span>E</span>
          </li>
          <li>
            <span>5</span>
          </li>
          <li>
            <span>F</span>
          </li>
        </>
      );
      break;
    // eslint-disable-next-line no-duplicate-case
    case 2:
      content = (
        <>
          <li>
            <span>6</span>
          </li>
          <li>
            <span>G</span>
          </li>
          <li>
            <span>7</span>
          </li>
          <li>
            <span>H</span>
          </li>
          <li>
            <span>8</span>
          </li>
          <li>
            <span>I</span>
          </li>
          <li>
            <span>9</span>
          </li>
          <li>
            <span>J</span>
          </li>
          <li>
            <span>10</span>
          </li>
          <li>
            <span>K</span>
          </li>
          <li>
            <span>11</span>
          </li>
        </>
      );
      break;

    // eslint-disable-next-line no-duplicate-case
    case 3:
      content = (
        <>
          <li>
            <span>L</span>
          </li>
          <li>
            <span>12</span>
          </li>
          <li>
            <span>M</span>
          </li>
          <li>
            <span>13</span>
          </li>
          <li>
            <span>N</span>
          </li>
          <li>
            <span>14</span>
          </li>
          <li>
            <span>O</span>
          </li>
          <li>
            <span>15</span>
          </li>
          <li>
            <span>P</span>
          </li>
          <li>
            <span>16</span>
          </li>
          <li>
            <span>Q</span>
          </li>
        </>
      );
      break;
    // eslint-disable-next-line no-duplicate-case
    case 4:
      content = (
        <>
          <li>
            <span>17</span>
          </li>
          <li>
            <span>R</span>
          </li>
          <li>
            <span>18</span>
          </li>
          <li>
            <span>S</span>
          </li>
          <li>
            <span>19</span>
          </li>
          <li>
            <span>T</span>
          </li>
          <li>
            <span>20</span>
          </li>
          <li>
            <span>U</span>
          </li>
          <li>
            <span>21</span>
          </li>
          <li>
            <span>V</span>
          </li>
          <li>
            <span>22</span>
          </li>
        </>
      );
      break;
    // eslint-disable-next-line no-duplicate-case
    case 5:
      content = (
        <>
          <li>
            <span>W</span>
          </li>
          <li>
            <span>23</span>
          </li>
          <li>
            <span>X</span>
          </li>
          <li>
            <span>23</span>
          </li>
          <li>
            <span>Y</span>
          </li>
          <li>
            <span>25</span>
          </li>
          <li>
            <span>Z</span>
          </li>
          <li>
            <span>26</span>
          </li>
        </>
      );
      break;

    default:
      break;
  }

  return content;
};

const getRandomColor = () => {
  const randomColor = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
    Math.random() * 256,
  )}, ${Math.floor(Math.random() * 256)}, 0.2)`;
  return randomColor;
};
export const AnimatedBackground = () => {
  const [randomNumber, setRandomNumber] = useState<number>(1);
  // const [bg1] = useState(new Audio("/sounds/bg/1.mp3"));

  useEffect(() => {
    const liElements = document.querySelectorAll(".circles li");

    const changeColors = () => {
      liElements.forEach((li) => {
        (li as HTMLElement).style.background = getRandomColor();
      });
    };

    const intervalId = setInterval(() => {
      setRandomNumber(Math.floor(Math.random() * 5) + 1);
      // bg1.play();
      // bg1.currentTime = 0;
    }, 20000);

    const colorChangeIntervalId = setInterval(changeColors, 3000);

    return () => {
      clearInterval(intervalId);
      clearInterval(colorChangeIntervalId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Box>
      <div className="area">
        <ul className="circles">
        {charNum(randomNumber)}
        </ul>
      </div>
    </Box>
  );
};