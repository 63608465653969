import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import gameService from '../../service/game/gameService';

interface GlobalScoreState {
  data: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  errorMessage: string;
}

const initialState: GlobalScoreState = {
  data: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: '',
};

export const GlobalScoreAction = createAsyncThunk('auth/globalScore', async (data, thunkAPI) => {
  try {
    return await gameService.getGlobalScores();
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});

export const spellingSlice = createSlice({
  name: 'globalScore',
  initialState,
  reducers: {
    resetResults: (state) => initialState,

  },
  extraReducers: (builder) => {
    builder
      .addCase(GlobalScoreAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GlobalScoreAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = action.payload; 
      })
      .addCase(GlobalScoreAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload as string;
      });
  },
});

export const { resetResults } = spellingSlice.actions;
export default spellingSlice.reducer;