import {
  Box,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  Text,
  Flex,
  Link,
  Icon,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  FiHome,
  FiRadio,
  FiFile,
} from "react-icons/fi";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  variant: any;
}
const navItems = [
  {  title: "About", to: "/about-recaptcha", Icon: FiHome  },
  {  title: "Api Reference", to: "/api-reference",  Icon: FiFile },
  {  title: "Api Key", to: "/api-key",  Icon: FiRadio },
];

const SidebarContent: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  
  <Box textAlign={"left"} mt={20}>
    {navItems.map((item) => (
       <Flex key={item.to}  mt={3} alignItems="center">
       <Link
         as={ReactRouterLink}
         to={item.to}
         ml={5}
         display={"block"}
         width={"100%"}
         p={2}
         borderRadius={3}
         _hover={{ color: "#319795", bg:"#edf2f7" }}
       >
        <Icon
            as={item.Icon}
          />
          <Text
            as={"span"}
            ml={1}
          >
             {item.title}
          </Text>
        
       </Link>
     </Flex>
    ))}
   
  </Box>
);
export const SidebarApiReference: React.FC<Props> = ({
  isOpen,
  variant,
  onClose,
}) => {
  return variant === "sidebar" ? (
    <Box
      position="fixed"
      left={0}
      p={5}
      w="230px"
      top={0}
      h="100%"
      bg="#FFF"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
    >
      <SidebarContent onClick={onClose} />
    </Box>
  ) : (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Api Reference</DrawerHeader>
          <DrawerBody>
            <SidebarContent onClick={onClose} />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};
