import { FC } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Label,
  Tooltip,
} from "recharts";
import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  Link,
} from "@chakra-ui/react";
import { GameResult } from "../../../../redux/service/schema";
import { calculateTimeTaken } from "../SpellingResult";

interface ResultProps {
  result: GameResult;
  isBackBtn?: boolean;
}

interface MiddleLabelProps {
  viewBox: { cx: number; cy: number };
  labelText: string;
  value: number;
}

const MiddleLabel: FC<MiddleLabelProps> = ({ viewBox, labelText, value }) => {
  const { cx, cy } = viewBox;
  return (
    <g>
      <text
        x={cx}
        y={cy + 0}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fill="#666;"
        fontSize="30"
        fontWeight="600"
      >
        {value}
        {labelText}
      </text>
    </g>
  );
};

export const EducationResult: FC<ResultProps> = ({ result, isBackBtn = false }) => {
  const totalQuestions = parseInt(result.numberOfQuestions, 10);
  const score = result.score;
  const percentage = ((score / (totalQuestions * 5)) * 100).toFixed(2);
  const correctAnswers = Math.floor(score / 5);
  const incorrectAnswers = totalQuestions - correctAnswers;

  const data = [
    { name: "Correct", value: correctAnswers },
    { name: "Incorrect", value: incorrectAnswers },
  ];

  const COLORS = ["#2f855a", "#c53030"];

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"}>
      <Stack
        spacing={8}
        mx={"auto"}
        maxW={"xl"}
        py={12}
        px={6}
        width={"full"}
        bg={"#FFF"}
      >
        <Box textAlign="center" p={6} boxShadow="lg" borderRadius="md">
          { isBackBtn ? <Box textAlign="left">
            <Link
              href={`/game-result/${result.gameId}`}
              color="#319795"
              fontSize={{ base: 14, sm: 17 }}
              me="10px"
            >
              <Button h={8} colorScheme="teal" variant="outline">
                Back
              </Button>
            </Link>
          </Box> : "" }
          <Heading
            mb={4}
            fontSize={20}
            color={"rgb(242, 107, 58)"}
            textTransform={"capitalize"}
          >{`Result - ${result.gameCategory}`}</Heading>
          <Heading mb={4} fontSize={17} textTransform={"capitalize"}>
            Name: {`${result.gameName}`}
          </Heading>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={data}
                dataKey="value"
                cx="50%"
                cy="50%"
                innerRadius={80}
                outerRadius={100}
                fill="#8884d8"
                label={({ value, name }) => `${name}: ${value}`}
              >
                {data.map((entry, index) => (
                  <Cell key={index} fill={COLORS[index % COLORS.length]} />
                ))}
                <Label
                  content={
                    <MiddleLabel
                      labelText="%"
                      value={parseInt(percentage)}
                      viewBox={{
                        cx: 0,
                        cy: 0,
                      }}
                    />
                  }
                  position="center"
                />
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
          <Text mt={4}>
            You answered {correctAnswers} out of {totalQuestions} questions
            correctly.
          </Text>
          <Box textAlign="left" p={6} borderRadius="md">
            <Text color={"#666"} fontWeight={"bold"}>
              User Name:{" "}
              <Text as={"span"} color={"#319795"}>
                {result.userName}
              </Text>
            </Text>
            <Text color={"#666"} fontWeight={"bold"}>
              Time Taken:{" "}
              <Text as={"span"} color={"#319795"}>
                {calculateTimeTaken(result)}
              </Text>
            </Text>
            <Text color={"#666"} fontWeight={"bold"}>
              Date:{" "}
              <Text as={"span"} color={"#319795"}>
                {result.created_at}
              </Text>
            </Text>
          </Box>
        </Box>
      </Stack>
    </Flex>
  );
};
