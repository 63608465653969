import React, { FC, ReactNode } from "react";
import { motion } from "framer-motion";

interface MotionElementProps {
  children: ReactNode;
  type: "fadeInX" | "fadeInY";
}

export const MotionElement: FC<MotionElementProps> = ({ children, type }) => {
  const animationVariants = {
    fadeInX: { opacity: 1, x: 0 },
    fadeInY: { opacity: 1, y: 0 },
  };

  const getAnimationType = () => {
    switch (type) {
      case "fadeInX":
        return "fadeInX";
      case "fadeInY":
        return "fadeInY";
      default:
        return "fadeInX";
    }
  };

  const animationType = getAnimationType();

  return (
    <motion.div
      initial={{ opacity: 0, ...(type === "fadeInY" && { y: -20 }), ...(type === "fadeInX" && { x: -50 }) }}
      animate={animationVariants[animationType]}
      exit={{ opacity: 0, ...(type === "fadeInY" && { y: -20 }) }}
      transition={{ duration: 0.8 }}
    >
      {children}
    </motion.div>
  );
};
