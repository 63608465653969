import SyntaxHighlighter from "react-syntax-highlighter";
import { monokaiSublime } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Text} from "@chakra-ui/react";

export const PHPApiSampleCode = () => {
const codeSampleGetQuestion = `
    /**
    * Retrieves a Re-captcha question of the specified type and word length.
    *
    * @param {string} type The type of Re-captcha question to retrieve. 
    * type: Must be one of the following: CHARACTERS, NUMBERS, RANDOM, or COMPLEX.
    * @param {integer} wordLength The length of the Re-captcha word to retrieve.
    * @param {string} apiKey The API key obtained from https://www.thelastspellingbee.com/re-captcha/apikey.
    * @return {Promise} A promise that resolves with the Re-captcha question and answer.
    */
   
     <?php

     $type = "characters";
     $wordLength = 5;
     $apikey = "fadshjgckxvl536789ewdgv";
     
     $url = "https://www.thelastspellingbee.com/v1/api/code-bender/re-captcha/".$type."/".$wordLength."/".$apikey;
     
     $curl = curl_init();
     
     curl_setopt_array($curl, array(
       CURLOPT_URL => $url,
       CURLOPT_RETURNTRANSFER => true,
       CURLOPT_ENCODING => "",
       CURLOPT_MAXREDIRS => 10,
       CURLOPT_TIMEOUT => 30,
       CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
       CURLOPT_CUSTOMREQUEST => "GET",
     ));
     
     $response = curl_exec($curl);
     $err = curl_error($curl);
     
     curl_close($curl);
     
     if ($err) {
       echo "cURL Error #:" . $err;
     } else {
       echo $response;
     }
     
     ?>`;

const codeSampleAnswerQuestion = `
<?php
/**
 * Submits an answer to a Re-captcha question.
 *
 * @param {string} question The Re-captcha question.
 * @param {string} answer The answer to the Re-captcha question.
 * @param {string} apiKey The API key obtained from https://www.thelastspellingbee.com/re-captcha/apikey.
 * @return {Promise} A promise that resolves with a boolean indicating whether the answer was correct or not.
 */

function submitReCaptchaAnswer($question, $answer, $apikey) {
    // Set API endpoint URL
    $endpoint = "https://www.thelastspellingbee.com/v1/api/code-bender/answer-re-captcha";
    
    // Prepare POST data
    $data = array(
        'question' => $question,
        'answer' => $answer,
        'apikey' => $apikey
    );
    
    // Create cURL handle
    $ch = curl_init();
    
    // Set cURL options
    curl_setopt($ch, CURLOPT_URL, $endpoint);
    curl_setopt($ch, CURLOPT_POST, true);
    curl_setopt($ch, CURLOPT_POSTFIELDS, http_build_query($data));
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    
    // Execute cURL request
    $result = curl_exec($ch);
    
    // Close cURL handle
    curl_close($ch);
    
    // Decode JSON response
    $result = json_decode($result);
    
    // Check if answer is correct
    if ($result->status == "success") {
        return true;
    } else {
        return false;
    }
}

// Example usage
$question = "What is DAD?";
$answer = "414";
$apikey = "my-secret-api-key";

if (submitReCaptchaAnswer($question, $answer, $apikey)) {
    echo "Answer is correct!";
} else {
    echo "Answer is incorrect.";
}
?>
`;

    return(
       <>
            <Text fontWeight={"bold"} mt={9} mb={1}>Get Question</Text>
            <SyntaxHighlighter language="javascript" style={monokaiSublime}>
                {codeSampleGetQuestion}
            </SyntaxHighlighter>
            <Text fontWeight={"bold"} mt={9} mb={1}>Answer Question</Text>
            <SyntaxHighlighter language="javascript" style={monokaiSublime}>
                {codeSampleAnswerQuestion}
            </SyntaxHighlighter>
       </>
    )
}