import React, { useEffect } from "react";
import * as yup from 'yup';
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  useToast,
  Icon,
  Box,
  Spinner,
  Heading,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'; 
import { FaPlus } from "react-icons/fa";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/useTypedSelector";
import { createNewApiKeyAction, resetCreateNewApiKey } from "../../redux/slice/api-key/recaptchaSlice";


export const CreateNewApiKey = () => {
  const { data } = useAppSelector((state: { auth: any; }) => state.auth);
  const { isError, isSuccess, errorMessage } = useAppSelector((state: { ApiKey: any; }) => state.ApiKey);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const dispatch = useAppDispatch();
  const toast = useToast();
 
  const ApiKeyValidationSchema = yup.object().shape({
    user_handle: yup.number().required('Company ID is required'),
    domain_name: yup.string().required('Domain is required'),
  });
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm({ 
    resolver: yupResolver(ApiKeyValidationSchema),
    defaultValues: {
      user_handle: data.company.user.id,
      domain_name: ""
    },
  });

  useEffect(() => {
    if (isError) {
        toast({
            title: "Error creating Api Key",
            description: errorMessage,
            status: "error",
            position: "top-right",
            duration: 9000,
            isClosable: true,
          });
          dispatch(resetCreateNewApiKey());
    }
    if (isSuccess) {
        toast({
            title: "Api Key created.",
            description: "Api Key created successfully",
            status: "success",
            position: "top-right",
            duration: 9000,
            isClosable: true,
          });
          dispatch(resetCreateNewApiKey());
        // window.location.reload();
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess, errorMessage, toast])

  
const onSubmitHandler = async (data: any) => {
   try {
    await dispatch(createNewApiKeyAction(data));
   } catch (error: any) {
    console.log(error.message);
   }
};


  return (
    <>
      <Button onClick={onOpen} colorScheme={"teal"} variant={"outline"}>
        <Icon as={FaPlus} />
        <Text ml={1}>Create new Api Key</Text>
      </Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent p={3}>
          <ModalHeader>
            <Heading fontSize={20}>Create New Api Key</Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box
              as={"form"}
              mt={0}
              onSubmit={handleSubmit(onSubmitHandler)}
            >
            <Text>
                After creating an API key, you can begin utilizing our Re-captcha API. 
                It is important to ensure the safety and protection of the key.
                Do you confirm that you wish to continue?
            </Text>
            <FormControl mt={4} isRequired>
                <FormLabel>Domain Name</FormLabel>
                <Input
                {...register("domain_name")}
                id="domain"
                placeholder="Domain name (eg. example.com)"
                isInvalid={!!errors.domain_name}
                required
                />
            </FormControl>
              <Button
                fontFamily={"heading"}
                mt={8}
                w={"full"}
                bgGradient="linear(to-r, #319795,#319795)"
                color={"white"}
                _hover={{
                  bgGradient: "linear(to-r, #5fc4b5,#5fc4b5)",
                  boxShadow: "xl",
                }}
                type="submit"
              >
                {isSubmitting ? <Spinner /> : "Submit"}
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
