import { Flex, Stack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { GameResult } from "../../redux/service/schema";
import { EducationResult } from "../spelling/game-play/result-chart/EducationResult";
import { TeamBondingResult } from "../spelling/game-play/result-chart/TeamBondingResult";
import { getGameCategory } from "../spelling/game-play/helper";


export const ResultDetails = () => {
const { game } = useParams();
const decodedString = atob(game ?? "");
const result = JSON.parse(decodedString);


  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"}>
      <Stack spacing={8} mx={"auto"} width={"full"} py={12} px={6} bg={"#FFF"}>
        {getGameCategory(result[0]) === "teamBonding" ? (
          <TeamBondingResult result={result  as GameResult[]} isBackBtn={true} isAuthView={true} />
        ) : (
          <EducationResult result={result as GameResult} isBackBtn={true} />
        )}
      </Stack>
    </Flex>
  );
};
