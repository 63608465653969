import { Text, Box, ListItem, UnorderedList } from "@chakra-ui/react";

export const GameLevelExplanation = () => {
  return (
    <Box>
      <Box p={2} mb={3} mt={3} bg={"#FFF"} color={"#666"} borderRadius={3}>
        <Text>Characters Questions</Text>
        <UnorderedList>
          <ListItem> Level: Beginner</ListItem>
          <ListItem> Example Question : DADDY</ListItem>
          <ListItem> Answer: 4 1 4 4 25</ListItem>
      </UnorderedList>
      </Box>
      <Box p={2} mb={3} bg={"#FFF"} color={"#666"} borderRadius={3}>
        <Text>Numbers Question</Text>
        <UnorderedList>
          <ListItem>Level: Advanced</ListItem>
          <ListItem>Example Question: 4 -1- 4 - 4 - 25</ListItem>
          <ListItem>Answer: D A D D Y</ListItem>
        </UnorderedList>
      </Box>
      <Box p={2} mb={3} bg={"#FFF"} color={"#666"} borderRadius={3}>
        <Text>Random Question</Text>
        <UnorderedList>
          <ListItem>Level: Professional</ListItem>
          <ListItem>
            Example: This is going to be displaying both Characters and number
            question
          </ListItem>
        </UnorderedList>
      </Box>
      <Box p={2} mb={3} bg={"#FFF"} color={"#666"} borderRadius={3}>
        <Text>Mixed Char and Num Question</Text>
        <UnorderedList>
          <ListItem>Level: Ultimate</ListItem>
          <ListItem>Example Question: D - 1 - D - 4 - Y</ListItem>
          <ListItem>Answer: 4 A 4 D 25</ListItem>
        </UnorderedList>
      </Box>
    </Box>
  );
};
