import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiKeyService from '../../service/api-key/apiKeyService';
import { ApiKey } from '../../service/schema';


interface ApiKeyState {
  apiKeys: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  errorMessage: string;
}

const initialState: ApiKeyState = {
  apiKeys: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: '',
};

const handleFulfilled= (state: ApiKeyState, action: any, method?: "get"|"create") => {
  if(action.payload.status === 500){
    state.isLoading = false
    state.isError = true
    state.isSuccess = false
    state.errorMessage = action.payload.errorMessage
}else{
  if(action.payload.isDomainName === 409){
    state.isLoading = false
    state.isError = true
    state.isSuccess = false
    state.errorMessage = action.payload.errorMessage
  }else{
    state.isLoading = false
    state.isSuccess = method === "get" ? false : true;
    state.apiKeys = action.payload
  }
}
};
const handleRejected = (state: ApiKeyState, action: any) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = action.payload as string;
};


export const createNewApiKeyAction = createAsyncThunk('auth/createKey', async (data: ApiKey, thunkAPI) => {
  try {
    return await apiKeyService.createApiKey(data,thunkAPI);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});
export const getApiKeyAction = createAsyncThunk('auth/getKey', async (companyId: number, thunkAPI) => {
  try {
    return await apiKeyService.getApiKey(thunkAPI);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const spellingSlice = createSlice({
  name: 'ApiKey',
  initialState,
  reducers: {
    resetCreateNewApiKey: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.errorMessage = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewApiKeyAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewApiKeyAction.fulfilled, (state, action) => {
        handleFulfilled(state, action, "create");
      })
      .addCase(createNewApiKeyAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(getApiKeyAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getApiKeyAction.fulfilled, (state, action) => {
        handleFulfilled(state, action, "get");
      })
      .addCase(getApiKeyAction.rejected, (state, action) => {
        handleRejected(state, action);
      });
  },
});

export const { resetCreateNewApiKey } = spellingSlice.actions;
export default spellingSlice.reducer;