import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import gameService from '../../service/game/gameService';
import { IStartGame } from '../../service/schema';

interface CreateNewGameState {
  playGame: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  errorMessage: string;
}

const initialState: CreateNewGameState = {
  playGame: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: '',
};

const handleRejected = (state: CreateNewGameState, action: any) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = action.payload as string;
};

export const startGameAction = createAsyncThunk('auth/startGame', async (data: IStartGame, thunkAPI) => {
  try {
    return await gameService.startGame(data);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});

export const spellingSlice = createSlice({
  name: 'gamePlay',
  initialState,
  reducers: {
    resetGamePlay: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(startGameAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(startGameAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.playGame = action.payload as IStartGame; 
      })
      .addCase(startGameAction.rejected, (state, action) => {
        handleRejected(state, action);
      });
  },
});

export const { resetGamePlay } = spellingSlice.actions;
export default spellingSlice.reducer;
