import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Text,
  Box,
  Flex,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FiInfo } from "react-icons/fi";

export const instructionText = `If the letters of the alphabet are assigned numerical values from 1
to 26, with A being 1, B being 2, and so on until Z being 26, and
the numbers from 1 to 26 are assigned letter values with 1 being A,
2 being B, and so on until 26 being Z, what is the letter
representation of the number DAD? The answer will be 414.`;

export const Instructions = () => {
  return (
    <Accordion allowMultiple width="100%" maxW="lg" rounded="lg">
      <AccordionItem border={"1px #FFF solid"}>
        <AccordionButton
          display="flex"
          alignItems="left"
          justifyContent="space-between"
          textAlign={"left"}
          p={2}
        >
          <Box color={"#319795"} fontWeight={"bold"}>
            <Flex alignItems="center">
              <FiInfo />
              <Text as={"span"} ml={2}>
                Instruction on how to play
              </Text>
            </Flex>
          </Box>

          <ChevronDownIcon fontSize="24px" />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <Text color="gray.600" textAlign={"justify"}>
            {instructionText}
          </Text>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
