import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Text,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { useForm} from "react-hook-form"; 
import { InvitePlayers } from "./InvitePlayers";
import { Instructions } from "./Instructions";
import { IStartGame } from "../../redux/service/schema";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/useTypedSelector";
import { useNavigate, useParams } from "react-router-dom";
import { startGameAction } from "../../redux/slice/game/spellingSlice";
import countryList from 'react-select-country-list';
import { useMemo } from "react";
import { CustomSelect } from "../comon/CustomSelect";


export const Spell = () => {
  const { data } = useAppSelector((state: { newGame: any; }) => state.newGame);
  const countryOptions = useMemo(() => countryList().getData(), []);
  const { inviteLink } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();

const inviteId = data
    ? `${data[0]?.id}-${data[0]?.handle}`
    : inviteLink;

  const {
      handleSubmit,
      register,
      formState: { isSubmitting },
    } = useForm({ 
      defaultValues: {
        handle: inviteId,
        userName: "",
        country: "",
      }
    });


const onSubmitHandler = async (data: any) => {
  try {
       await dispatch(startGameAction(data as IStartGame));
        toast({
          title: 'Game created.',
          description: "We've created your game for you.",
          status: 'success',
          position: 'top-right',
          duration: 9000,
          isClosable: true,
        });
        localStorage.removeItem('thelastspreult');
        navigate(`/spelling`);   
  } catch (error: any) {
   console.log(error.message);
  }
};

  if(!inviteId){
    return <Spinner /> ;
  }

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={'gray.50'}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        { data ? <InvitePlayers inviteId={inviteId} /> : ""}

        <Box
          rounded={"lg"}
          bg={'gray.50'}
          boxShadow={"lg"}
          p={8}
        >
          <Instructions />
          <Box as={"form"} mt={0}  onSubmit={handleSubmit(onSubmitHandler)}>
          <Stack spacing={4} mt={5}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                {...register("userName")}
                id="userName"
                placeholder="Enter your name"
                required
              />
            </FormControl>
            <FormControl>
              <FormLabel>Country Location</FormLabel>
              <CustomSelect
                register={register}
                id="country"
                placeholder="Select Country"
                defaultValue={""}
                options={countryOptions}
              />
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Text>
                  Your Journey to Unleash Your Inner Memory Maestro Begins Here
                </Text>
              </Stack>
              <Button
                bg={"#319795"}
                color={"white"}
                _hover={{
                  bg: "#319795",
                }}
                type="submit"
              >
                 {isSubmitting ? <Spinner /> : "Play"} 
              </Button>
            </Stack>
          </Stack>
          </Box>
        </Box>
      </Stack>
    </Flex>
  );
};

