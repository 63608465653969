import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
} from "@chakra-ui/react";
import { GameResult } from "../../../../redux/service/schema";
import { calculateTimeTaken } from "../SpellingResult";
import { getExDate } from "../helper";
import dateFormat from "dateformat";

export interface PlayerResultProps {
  name: string;
  value: number;
  correct: number;
  incorrect: number;
  result: GameResult;
}

interface PlayerDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  player: PlayerResultProps | undefined;
}

export const PlayerDetailsModal: React.FC<PlayerDetailsModalProps> = ({
  isOpen,
  onClose,
  player,
}) => {

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color={"#319795"}>{player?.name}'s Details</ModalHeader>
        <ModalHeader textAlign={"center"}>
          Team: {player?.result.gameName}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text color={"#666"} fontWeight={"bold"}>
            Score:{" "}
            <Text as={"span"} color={"#319795"}>
              {player?.value}%
            </Text>
          </Text>
          <Text color={"#666"} fontWeight={"bold"}>
            Number of Questions:{" "}
            <Text as={"span"} color={"#319795"}>
              {player?.result.numberOfQuestions}
            </Text>
          </Text>
          <Text color={"#666"} fontWeight={"bold"}>
            Correct Answers:{" "}
            <Text as={"span"} color={"#319795"}>
              {player?.correct}
            </Text>
          </Text>
          <Text color={"#666"} fontWeight={"bold"}>
            Incorrect Answers:{" "}
            <Text as={"span"} color={"#319795"}>
              {player?.incorrect}
            </Text>
          </Text>
          <Text color={"#666"} fontWeight={"bold"}>
            Time Taken:{" "}
            <Text as={"span"} color={"#319795"}>
              {calculateTimeTaken(player?.result)}
            </Text>
          </Text>
          <Text color={"#666"} fontWeight={"bold"}>
            Date:{" "}
            <Text as={"span"} color={"#319795"}>
              {dateFormat(
                  getExDate(player?.result.game_created_at ?? ""),
                  "ddd, mmmm dS, yyyy",
                )}
            </Text>
          </Text>
        </ModalBody>
        <ModalFooter>
          
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
