import axios from "axios";
import {
  IAnswerGame,
  ICreateGameGameData,
  IFinishGameData,
  IStartGame,
} from "../schema";
import { config } from "../../../common/config";

const API_URL = config.baseUrl.default;

export const getTokenAndConfig = (thunkAPI: any) => {
  return {
    headers: {
      Authorization: `Bearer ${thunkAPI.getState().auth.data.company.token}`,
    },
  };
};
const createNewGame = async (data: ICreateGameGameData) => {
  const response = await axios.post(API_URL + "create-new-game", data);
  return response.data;
};
const startGame = async (data: IStartGame) => {
  const response = await axios.post(API_URL + "start-spelling", data);
  return response.data;
};
const answerSpelling = async (userAnswer: IAnswerGame) => {
  const config = {
    headers: {
      Authorization: `Bearer ${userAnswer.token}`,
    },
  };

  const response = await axios.post(
    API_URL + "check-answer",
    userAnswer,
    config,
  );

  localStorage.setItem("backfeedeng", btoa(JSON.stringify(response.data)));
  return response.data;
};
const completeSpelling = async (data: IFinishGameData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  };

  const response = await axios.post(
    API_URL + "spelling-complete",
    data,
    config,
  );

  localStorage.setItem("thelastspreult", btoa(JSON.stringify(response.data)));
  return response.data;
};
const getGames = async (thunkAPI: any) => {
  const response = await axios.get(
    API_URL + `get-games/${thunkAPI.getState().auth.data.company.user.id}`,
    getTokenAndConfig(thunkAPI),
  );
  return response.data;
};
const updateGame = async (data: any, thunkAPI: any) => {
  const response = await axios.put(
    API_URL + "update-game",
    data,
    getTokenAndConfig(thunkAPI),
  );
  return response.data;
};
const deleteGame = async (data: any, thunkAPI: any) => {
  console.log('data: ',data)
  const response = await axios.post(
    API_URL + "delete-game",
    data,
    getTokenAndConfig(thunkAPI),
  );
  return response.data;
};

const getGameResults = async (gameId: number, thunkAPI: any) => {
const _data = {
  gameId,
  company_id: thunkAPI.getState().auth.data.company.user.id,
}
  const response = await axios.post(
    API_URL + "get-spelling-score",
    _data,
    getTokenAndConfig(thunkAPI),
  );
  return response.data;
};
const getGlobalScores = async () => {
  const response = await axios.get(API_URL + 'get-global-scores');
  return response.data;
}
const gameService = {
  createNewGame,
  startGame,
  answerSpelling,
  completeSpelling,
  getGames,
  updateGame,
  deleteGame,
  getGameResults,
  getGlobalScores,
};

export default gameService;
