import { FC, useEffect, useState } from "react";
import { Suspense } from "react";
import {
  Box,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spinner,
  Button,
  ButtonGroup,
  Img,
} from "@chakra-ui/react";
import ReactCountryFlag from "react-country-flag"
import {
  useAppSelector,
  useAppDispatch,
} from "../../../redux/hooks/useTypedSelector";
import { GlobalScoreAction } from "../../../redux/slice/game/globalScoreSlice";
import { calculatePoints, calGameScore, getLevel } from "./helper";
import { calculateTimeTaken } from "./SpellingResult";
import { GameResult } from "../../../redux/service/schema";

const itemsPerPage = 10; 

export const GlobalScore: FC = () => {
  const { data, isLoading } = useAppSelector(
    (state: { globalScore: any }) => state.globalScore,
  );
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(GlobalScoreAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  const sortedData = (data?.result || [])
    .slice()
    .sort((a: { id: number }, b: { id: number }) => b.id - a.id);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Flex minH={"100vh"} pt={90} direction="column">
      <Box borderRadius="15px" px="0px" display="flex">
        <Heading color={"#319795"} fontSize="32px" mb={5} textAlign={"center"}>
          Global Score
        </Heading>
      </Box>

      <TableContainer>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th></Th>
              <Th>User</Th>
              <Th>Level</Th>
              <Th>Points</Th>
              <Th>Score</Th>
              <Th>Time Completed</Th>
            </Tr>
          </Thead>
          <Suspense>
          <Tbody>
            {currentItems &&
              currentItems.map((game: GameResult, index: any) => (
                <Tr key={index}>
                  <Td>{game.country} <ReactCountryFlag countryCode={game.country ?? "CA"} /></Td>
                  <Td>{game.userName}</Td>
                  <Td>{getLevel(game.questionType)}</Td>
                  <Td>
                    {calculatePoints(
                      parseInt(game.numberOfQuestions),
                      game.score,
                      game.timeCompleted,
                    )}
                  </Td>
                  <Td>
                   {calGameScore(game.numberOfQuestions, game.score) >= 80 && 
                      <Img src="images/crown.svg" mb={0} />}
                    {calGameScore(game.numberOfQuestions, game.score)}%
                  </Td>
                  <Td>{calculateTimeTaken(game)}</Td>
                </Tr>
              ))}
          </Tbody>
          </Suspense>
        </Table>
      </TableContainer>

 
      <Flex justify="center" mt={4}>
        <ButtonGroup size="sm" isAttached variant="outline">
          {Array.from({
            length: Math.ceil(sortedData.length / itemsPerPage),
          }).map((_, index) => (
            <Button
              key={index}
              onClick={() => paginate(index + 1)}
              cursor="pointer"
              color={currentPage === index + 1 ? "blue.500" : "black"}
              fontWeight={currentPage === index + 1 ? "bold" : "normal"}
              mx={2}
            >
              {index + 1}
            </Button>
          ))}
        </ButtonGroup>
      </Flex>
    </Flex>
  );
};
