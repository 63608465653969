import * as yup from 'yup';
import { ICreateGameGameData } from '../../../redux/service/schema';

export const GameValidationSchema = (type: "auth" | "unAuth") => {
  const commonShape = {
    gameName: yup.string().required('Game name is required'),
    gameCategory: yup.string().required('Game category is required'),
    questionType: yup.string().required('Question type is required'),
    wordLength: yup.number().required('Word length is required'),
    numberOfQuestions: yup.string().required('Number of questions is required'),
    gameTime: yup.string().required('Game time is required'),
  };

  const authSchema = {
    ...commonShape,
    expiryDate: yup.string().required('Expiry date is required'),
  };

  return yup.object().shape(type === 'auth' ? authSchema : commonShape);
};


export const GameDefaultValues = (game: ICreateGameGameData) =>{
  return {
    id: game.id,
    company_id: game.company_id,
    gameName: game.gameName,
    gameCategory: game.gameCategory,
    questionType: game.questionType,
    wordLength: game.wordLength,
    numberOfQuestions: game.numberOfQuestions,
    gameTime: game.gameTime,
    expiryDate: game.expiryDate || "", 
  }
}
