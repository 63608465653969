import SyntaxHighlighter from "react-syntax-highlighter";
import { monokaiSublime } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Text} from "@chakra-ui/react";

export const JavascriptApiSampleCode  = () => {
const codeSampleGetQuestion = `
    /**
    * Retrieves a Re-captcha question of the specified type and word length.
    *
    * @param {string} type The type of Re-captcha question to retrieve. 
    * type: Must be one of the following: CHARACTERS, NUMBERS, RANDOM, or COMPLEX.
    * @param {integer} wordLength The length of the Re-captcha word to retrieve.
    * @param {string} apiKey The API key obtained from https://www.thelastspellingbee.com/re-captcha/apikey.
    * @return {Promise} A promise that resolves with the Re-captcha question and answer.
    */
    
    function getReCaptchaQuestion(type, wordLength, apiKey) {
    return fetch('https://www.thelastspellingbee.com/v1/api/code-bender/re-captcha/{type}/{wordLength}', {
      headers: {
        'apikey': apiKey
      }
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.statusText);
      }
    });
  }`;
  const codeSampleAnswerQuestion = `
    /**
     * Submits an answer to a Re-captcha question.
     *
     * @param {string} question The Re-captcha question.
     * @param {string} answer The answer to the Re-captcha question.
     * @param {string} apiKey The API key obtained from https://www.thelastspellingbee.com/re-captcha/apikey.
     * @return {Promise} A promise that resolves with a boolean indicating whether the answer was correct or not.
     */

    //Example 1

    function answerReCaptchaQuestion(question, answer, apiKey) {
    return fetch('https://www.thelastspellingbee.com/v1/api/code-bender/answer-re-captcha', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey
        },
        body: JSON.stringify({
        question,
        answer
        })
    }).then(response => {
        if (response.ok) {
        return response.json();
        } else {
        throw new Error(response.statusText);
        }
    });
  }

  //Example 2

  async function submitReCaptchaAnswer(question, answer, apiKey) {
    try {
      const response = await fetch('https://www.thelastspellingbee.com/v1/api/code-bender/answer-re-captcha', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ question, answer, apiKey })
      });
  
      const json = await response.json();
      if (!response.ok) {
        throw new Error(json.message);
      }
      return json.answerIsCorrect;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
    `;

    return(
       <>
            <Text fontWeight={"bold"} mt={9} mb={1}>Get Question</Text>
            <SyntaxHighlighter language="javascript" style={monokaiSublime}>
                {codeSampleGetQuestion}
            </SyntaxHighlighter>
            <Text fontWeight={"bold"} mt={9} mb={1}>Answer Question</Text>
            <SyntaxHighlighter language="javascript" style={monokaiSublime}>
                {codeSampleAnswerQuestion}
            </SyntaxHighlighter>
       </>
    )
}