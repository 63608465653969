import {
  Flex,
  Box,
  Heading,
  Text,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../redux/hooks/useTypedSelector";
import { AnimatedTypingText } from "../comon/AnimatedTypingText";
import { CreateNewApiKey } from "./CreateNewApiKey";
import { useEffect, useState } from "react";
import { getApiKeyAction } from "../../redux/slice/api-key/recaptchaSlice";
import { ApiKey as IApiKey } from "../../redux/service/schema";
import dateFormat from "dateformat";
import { getExDate } from "../spelling/game-play/helper";

export const ApiKey = () => {
  const { data } = useAppSelector((state) => state.auth);
  const { apiKeys, isLoading } = useAppSelector((state) => state.ApiKey);
  const [apiKeysData, setApiKeysData] = useState();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getApiKeyAction(data.company.user.id as number));
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data.company.user.id]);

  useEffect(() => {
    setApiKeysData(apiKeys?.result)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKeys]);

  if (localStorage.getItem("isApiKeyNavigatePage")) {
    localStorage.removeItem("isApiKeyNavigatePage");
  }

  if (isLoading) {
    return <Spinner />;
  }

  const sortedApiKeys = [...(apiKeysData || [])].sort((a:any, b:any) => b.id - a.id);

  return (
    <Flex minH={"100vh"} pt={90} direction="column">
      <Box borderRadius="15px" px="0px" display="flex">
        <Heading color={"#319795"} fontSize="32px" mb={5} textAlign={"center"}>
          <AnimatedTypingText text="GET Re-captcha API Key" speed={0.1} />
        </Heading>
      </Box>
      <Flex
        borderRadius={5}
        px="0px"
        direction="column"
        p={5}
        bg={"#FFF"}
        boxShadow={"lg"}
      >
        <Box borderRadius="15px" px="0px" display="flex">
          <Text>
            The following is a list of your confidential API keys. It is
            important to keep in mind that once generated, these keys will not
            be shown again.<br></br>
            <br></br>
            It is imperative that you keep your API key private and do not
            reveal it to others or include it in the browser or client-side
            code.
            <br></br>
            To ensure the security of your account,ThelastSpellingBee may replace
            any API keys that have been discovered to have been made public for
            safety purposes.
          </Text>
        </Box>
        <Box borderRadius="15px" px="0px" display="flex" >
        <Flex justify="flex-end" minW={'100%'}>
            <CreateNewApiKey  />
        </Flex>
        </Box>
        <Box borderRadius="15px" px="0px" display="flex" mt={10} minW={'100%'}>
      
          <TableContainer  minW={'100%'}>
            <Table variant="striped" colorScheme="gray" minW={'100%'}>
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Key</Th>
                  <Th>Last Use</Th>
                  <Th>Created</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
              {sortedApiKeys &&
                sortedApiKeys.map((apiKey: IApiKey, index: any) => (
                    <Tr  key={index}>
                    <Td>{apiKey.domain_name}</Td>
                    <Td>{apiKey.api_key}</Td>
                    <Td>
                    {dateFormat(
                  getExDate(apiKey.updated_at ?? ""),
                  "ddd, mmmm dS, yyyy",
                )}</Td>
                    <Td>
                    {dateFormat(
                  getExDate(apiKey.created_at ?? ""),
                  "ddd, mmmm dS, yyyy",
                )}</Td>
                    <Td></Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Flex>
    </Flex>
  );
};