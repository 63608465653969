import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { CSharpApiSampleCode } from "./api-sample-code/CSharpApiSampleCode";
import { PHPApiSampleCode } from "./api-sample-code/PHPApiSampleCode";
import { JavaApiSampleCode } from "./api-sample-code/JavaApiSampleCode";
import { JavascriptApiSampleCode } from "./api-sample-code/JavascriptApiSampleCode";
import { PythonApiSampleCode } from "./api-sample-code/PythonApiSampleCode";
import { RubyApiSampleCode } from "./api-sample-code/RubyApiSampleCode";

export const ApiReferenceSample = () => {
  return (
    <Box>
      <Tabs>
        <TabList>
          <Tab>Node.js</Tab>
          <Tab>Java</Tab>
          <Tab>C#</Tab>
          <Tab>Python</Tab>
          <Tab>Ruby</Tab>
          <Tab>PHP</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <JavascriptApiSampleCode />
          </TabPanel>
          <TabPanel>
            <JavaApiSampleCode />
          </TabPanel>
          <TabPanel>
            <CSharpApiSampleCode />
          </TabPanel>
          <TabPanel>
            <PythonApiSampleCode />
          </TabPanel>
          <TabPanel>
            <RubyApiSampleCode />
          </TabPanel>
          <TabPanel>
            <PHPApiSampleCode />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
