import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import DefaultNav from "./apps/nav/DefaultNav";
import { Box, ChakraProvider } from "@chakra-ui/react";
import { DefaultFooter } from "./apps/nav/DefaultFooter";
import AppRoutes from "./AppRoutes";
import { useAppSelector } from "./redux/hooks/useTypedSelector";

function App() {
  const { data } = useAppSelector((state) => state.auth);

  return (
    <Router>
      <ChakraProvider>
        <DefaultNav />
        <Box
          ml={data?.company ? { base: '0px', sm: "275px" } : undefined}
          w={data?.company
            ? {
                base: '100%',
                sm: "80%",
              }
            : undefined
          }
          padding={{ base: 2, sm: 9 }}
          >
        <AppRoutes/>
        </Box>
        <DefaultFooter />
      </ChakraProvider>
    </Router>
  );
}

export default App;