import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ContactUs } from '../../../apps/auth/schema';
import authService from '../../service/auth/authService';

interface ContactUsState {
  data: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  errorMessage: string;
}

const initialState: ContactUsState = {
  data: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: '',
};

const handleRejected = (state: ContactUsState, action: any) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = action.payload as string;
};

export const contactUsAction = createAsyncThunk('contact-us', async (data: ContactUs, thunkAPI) => {
  try {
    return await authService.contactUs(data);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});

export const spellingSlice = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {
    resetContactUs: (state) => initialState,

  },
  extraReducers: (builder) => {
    builder
      .addCase(contactUsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(contactUsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = action.payload; 
      })
      .addCase(contactUsAction.rejected, (state, action) => {
        handleRejected(state, action);
      });
  },
});

export const { resetContactUs } = spellingSlice.actions;
export default spellingSlice.reducer;