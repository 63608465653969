import axios from 'axios'
import { config } from '../../../common/config';
import { ContactUs, ISignUp, RecoverPassword, ResetPassword } from '../../../apps/auth/schema';

const API_URL = config.baseUrl.default;

const signUp = async (data: ISignUp) => {
    const response = await axios.post(API_URL + 'company-signup', data);
    return response.data;
}
const signIn = async (data: ISignUp) => {
  const response = await axios.post(API_URL + 'company-signin', data);
  return response.data;
}
const verifyEmail = async (token: string) => {
  const response = await axios.post(API_URL + `verify-email/${token}`)
  return response.data;
}
const updateAccount = async (payload: ISignUp,thunkAPI: any) => {
  const config = {
      headers: {
        Authorization: `Bearer ${thunkAPI.getState().auth.data.company.token}`,
      },
    }
  const response = await axios.put(API_URL + 'update-company-info', payload, config);
  return response.data;
}
const recoverPassword = async (email: RecoverPassword) => {
  const response = await axios.post(API_URL + 'send-reset-password', email)
  return response.data;
}
const resetPassword = async (payload: ResetPassword) => {
  const response = await axios.post(API_URL + 'reset-password', payload)
  return response.data;
}
const contactUs = async (payload: ContactUs) => {
  const response = await axios.post(API_URL + 'contact-us', payload)
  return response.data;
}
const authService = {
  signUp,
  signIn,
  updateAccount,
  verifyEmail,
  recoverPassword,
  resetPassword,
  contactUs,
}

export default authService;