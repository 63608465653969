import axios from "axios";
import { config } from "../../../common/config";
import { getTokenAndConfig } from "../game/gameService";
import { ApiKey } from "../schema";

const API_URL = config.baseUrl.default;

const createApiKey = async (payload: ApiKey, thunkAPI: any) => {
  const response = await axios.post(
    API_URL + "create/re-captcha/api-key",
    payload,
    getTokenAndConfig(thunkAPI),
  );
  return response.data;
};
const deactivateKey = async (payload: ApiKey, thunkAPI: any) => {
  const response = await axios.post(
    API_URL + "deactivate/re-captcha/api-key",
    payload,
    getTokenAndConfig(thunkAPI),
  );
  return response.data;
};

const getApiKey = async (thunkAPI: any) => {
  const response = await axios.get(
    API_URL +
      `get-re-captcha-apikey/${thunkAPI.getState().auth.data.company.user.id}`,
    getTokenAndConfig(thunkAPI),
  );
  return response.data;
};

const apiKeyService = {
  createApiKey,
  deactivateKey,
  getApiKey,
};

export default apiKeyService;
