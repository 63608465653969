import { Button } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

interface GameTimeProps {
  initialMinuetsBySeconds: number;
  onTimerEnd?: (currentGameTime: string) => void;
}
export const formatTime = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours}:${String(minutes).padStart(2, "0")}:${String(
    remainingSeconds,
  ).padStart(2, "0")}`;
};

export const GameTime: React.FC<GameTimeProps> = ({
  initialMinuetsBySeconds: initialMinuetsBySceconds,
  onTimerEnd,
}) => {
  const [gameCompleteTime, setGameCompleteTime] = useState<string>("");
  const localStorageKey = "currentGameTime";
  const storedStartTime = localStorage.getItem(localStorageKey);
  const initialTimestamp = storedStartTime
    ? parseInt(storedStartTime, 10)
    : Date.now();
  const elapsedSeconds = Math.floor((Date.now() - initialTimestamp) / 1000);

  const [timeRemaining, setTimeRemaining] = useState<number>(
    Math.max(initialMinuetsBySceconds - elapsedSeconds, 0),
  );

 
  useEffect(() => {
    localStorage.setItem(localStorageKey, initialTimestamp.toString());
    localStorage.setItem('timeOnComplete', formatTime(timeRemaining));

    let timerId: NodeJS.Timeout;
    const updateTimer = () => {
      setTimeRemaining((prevTime) => {
        if (prevTime === 0) {
          if (onTimerEnd) {
            setGameCompleteTime(formatTime(timeRemaining));
            onTimerEnd(gameCompleteTime);
          }
          clearInterval(timerId);
          return 0;
        }
        return prevTime - 1;
      });
    };

    timerId = setInterval(updateTimer, 1000);
    return () => clearInterval(timerId);
  }, [
    gameCompleteTime,
    initialMinuetsBySceconds,
    initialTimestamp,
    onTimerEnd,
    timeRemaining,
  ]);

  return (
    <Button size="sm" variant="white" cursor={"default"}>
      {formatTime(timeRemaining)}
    </Button>
  );
};
