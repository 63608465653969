import { Component } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Home } from './apps/home';
import { Spell } from './apps/spelling/Spell';
import { SpellingGamePlay } from './apps/spelling/game-play/SpellingGamePlay';
import { SpellingResult } from './apps/spelling/game-play/SpellingResult';
import { SignIn } from './apps/auth/SignIn';
import { SignUp } from './apps/auth/SignUp';
import { ForgetPassword } from './apps/auth/ForgetPassword';
import { Dashboard } from './apps/user/Dashboard';
import { useAppSelector } from './redux/hooks/useTypedSelector';
import { GameResult } from './apps/user/GameResult';
import { ResultDetails } from './apps/user/ResultDetails';
import { Settings } from './apps/user/Settings';
import { AboutRecaptcha } from './apps/recaptcha/AboutRecaptcha';
import { ApiReference } from './apps/recaptcha/ApiReference';
import { ApiKey } from './apps/apikey/ApiKey';
import { GlobalScore } from './apps/spelling/game-play/GlobalScore';
import { ContactUs } from './apps/home/ContactUs';
import { VerifyEmail } from './apps/auth/VerifyEmail';
import { ResetPassword } from './apps/auth/ResetPassword';
import { NotFound } from './apps/404/NotFound';


const AuthUserPermission = ({ element }: { element: JSX.Element }) => {
  const { data } = useAppSelector((state) => state.auth);
  if (!data?.company) {
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    if(url.pathname === "/api-key"){
      localStorage.setItem("isApiKeyNavigatePage", "api-key");
      return <Navigate to="/sign-in" />;
    }

    return <Navigate to="/" />;
  }
  return element;
};
const ISAUth = ({ element }: { element: JSX.Element }) => {
  const { data } = useAppSelector((state) => state.auth);
  if (data?.company) {
    const page_to_visit = localStorage.getItem('isApiKeyNavigatePage');
    if (page_to_visit) { 
      return <Navigate to="/api-key" />;
    }
    return <Navigate to="/dashboard" />;
  }

  return element;
};



class AppRoutes extends Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<ISAUth element={<Home />} />} />
        <Route path="/sign-in" element={<ISAUth element={<SignIn />} />} />
        <Route path="/sign-up" element={<ISAUth element={<SignUp />} />} />
        <Route path="/forget-password" element={<ISAUth element={<ForgetPassword />} />} />
        <Route path='/email-password-reset/:email/:token' element={<ResetPassword />} />
        <Route path="/spell" element={<ISAUth element={<Spell />} />} />
        <Route path="/spell/:inviteLink" element={<ISAUth element={<Spell />} />} />
        <Route path="/spelling" element={<ISAUth element={<SpellingGamePlay />} />} />
        <Route path="/spelling-result" element={<ISAUth element={<SpellingResult />} />} />
        <Route path="/global-score" element={<ISAUth element={<GlobalScore />} />} />
        <Route path='/verify-email/:token' element={<VerifyEmail />} />

        {/* None Protected User Route */}
        <Route path="/contact-us" element={<ContactUs />} />



        {/* Re-Captcha Route */}
        <Route path="/api-reference" element={<ISAUth element={<ApiReference />} />} />
        <Route path="/about-recaptcha" element={<ISAUth element={<AboutRecaptcha />} />} />
        

        {/* Protected User Route */}
        <Route path="/dashboard" element={<AuthUserPermission element={<Dashboard />} />} />
        <Route path="/game-result/:gameId" element={<AuthUserPermission element={<GameResult />} />} />
        <Route path="/result-details/:game" element={<AuthUserPermission element={<ResultDetails />} />} />
        <Route path="/settings" element={<AuthUserPermission element={<Settings />} />} />
        <Route path="/api-key" element={<AuthUserPermission element={<ApiKey />} />} />
        <Route path="/global-result" element={<AuthUserPermission element={<GlobalScore />} />} />


        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }
}

export default AppRoutes;
