import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import gameService from '../../service/game/gameService';
import { IFinishGameData } from '../../service/schema';

interface FinishGameState {
  resultData: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  errorMessage: string;
}

const initialState: FinishGameState = {
  resultData: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: '',
};

const handleRejected = (state: FinishGameState, action: any) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = action.payload as string;
};

export const gameCompleteAction = createAsyncThunk('auth/gameComplete', async (data: IFinishGameData, thunkAPI) => {
  try {
    return await gameService.completeSpelling(data);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});

export const gameCompleteSlice = createSlice({
  name: 'gameComplete',
  initialState,
  reducers: {
    resetGameComplete: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(gameCompleteAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(gameCompleteAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.resultData = action.payload.data ?? []; 
      })
      .addCase(gameCompleteAction.rejected, (state, action) => {
        handleRejected(state, action);
      });
  },
});

export const { resetGameComplete } = gameCompleteSlice.actions;
export default gameCompleteSlice.reducer;